<template>
    <div class='sign-up-header'>
            <img src='../../public/Invrs-Logo-Green-Blue.png' class="nav-image" style="cursor: pointer;" @click="goHome()">
            
            <Button label="Sign In" class="p-button-text sign-in-button" @click="goToLoginPage()" />
    </div>

    <div class='sign-in-container'>
        <span class='sign-in-title'>Password Reset.</span>
                <div class='input-container'>
                    <!-- Desktop -->
                    <template v-if='!isMobile()'>
                        <div class='input-section'>
                            <span class='p-float-label' style="padding-right: 10px;">
                                <InputText id="email" type="text" v-model="email" @click="resetError('email')"/>
                                <label for="email" class='input-label'>Enter Your Email</label>
                            </span>
                        </div>
                        <div class="input-section">
                            <span :class="{ 'p-float-label': true, 'input-error': v$.password.$error }">
                                <Password placeholder="Password" id="password" v-model="password" toggleMask :mediumRegex="mediumPasswordRegex" :strongRegex="strongPasswordRegex" @input="resetError('password'), typingPassword()">
                                    <template #footer v-if="!passwordIsLongEnough">
                                        <Divider />
                                        <p class="p-mt-2 p-text-bold" style="color: #32364e;">Requirements:</p>
                                        <ul class="p-pl-2 p-ml-2 p-mt-0">
                                            <li>At least one number</li>
                                            <li>Minimum 6 characters</li>
                                        </ul>
                                    </template>
                                </Password>
                            </span>
                            <template v-for="error of v$.password.$errors" :key="error.$uid">
                                <span class="error-message">{{ error.$message }}</span>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <!-- Mobile -->
                        <span style="padding-bottom: 10px">
                            <InputText id="username" placeholder="Enter Your Email" type="text" v-model="email" @click="resetError('email')"/>
                        </span>
                        <div class="input-section">
                            <span :class="{ 'p-float-label': true, 'input-error': v$.password.$error }">
                                <Password placeholder="Password" id="password" v-model="password" toggleMask :mediumRegex="mediumPasswordRegex" :strongRegex="strongPasswordRegex" @input="resetError('password'), typingPassword()">
                                    <template #footer v-if="!passwordIsLongEnough">
                                        <Divider />
                                        <p class="p-mt-2 p-text-bold" style="color: #32364e;">Requirements:</p>
                                        <ul class="p-pl-2 p-ml-2 p-mt-0">
                                            <li>At least one number</li>
                                            <li>Minimum 6 characters</li>
                                        </ul>
                                    </template>
                                </Password>
                            </span>
                            <template v-for="error of v$.password.$errors" :key="error.$uid">
                                <span class="error-message">{{ error.$message }}</span>
                            </template>
                        </div>
                    </template>
                </div>
                <div class='button-container'>
                    
                    <Button label='Reset' class='sign-in-button' @click="resetPassword" :disabled="disableResetButton" :loading="loading"/>
                </div>
    </div>
    <Toast position="center" group="center" style="z-index:30000;" :autoZIndex="false"/>
</template>

<script>
import { HOME } from "@/common/config";

import UserService from '../service/UserService';
import AppService from '../service/AppService';//eslint-disable-line
import BrowserUtils from '../utilities/BrowserUtils';
import CustomValidationUtils from '../utilities/CustomValidationUtils';

import Divider from 'primevue/divider';
import Toast from 'primevue/toast';
import Password from 'primevue/password';

import useVuelidate from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';

const requiredCustomMessage = helpers.withMessage('Field is required.', required);

export default {
    name: 'ResetPassword',
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Divider, Toast, Password
    },
    data () {
        return {
            email: null,
            password: '',
            loading: false,
            resetSuccessful: false,

            mediumPasswordRegex: '^(?=.*[0-9])(?=.{6,})',
            strongPasswordRegex: '^(?=.*[\\W|_])(?=.*[0-9])(?=.{8,})',
        }
    },
    computed: {
        passwordIsLongEnough() {
            return this.password?.length >= 6;  
        },
        disableResetButton() {
            return (this.v$.password.$error || !this.password) || this.v$.email.$error || this.resetSuccessful;
        },
    },

    mounted() {
        AppService.cleanUpApp();// in case they logged in previously
        //console.log("token = "+ this.$route.params.token);
    },

    methods: {
        typingPassword() {
            this.v$.password.$touch();
        },

        resetError(field) {
            this.v$[field].$reset();
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        goHome() {
            window.location.href= HOME;
        },
       

         goToLoginPage() {
            this.$router.push({path:'/login'});
        },
        resetPassword() {
            this.loading = true;
            UserService.resetPassword(this.email, this.password, this.$route.params.token).then( (resp) => {
                if( resp.data.status == "success"){
                   this.$toast.add({ severity: 'success', summary: "Your password has been reset.  You will now be redirected back to the Sign In page.", life: 5000, group: 'center' });
                   this.loading = false;
                   this.resetSuccessful = true;
                   setTimeout(this.goToLoginPage, 5000);
                   
                }else {
                    let msg = (resp.data.message ?  resp.data.message : "Sorry an expected error occurred while reseting your password.  If the problem perisists please contact support@invrs.com.")
                    this.$toast.add({ severity: 'error', summary: msg, life: 5500, group: 'center' });
                    this.loading = false;
                }
            });
        },
        
    },

    validations() {
        return {
            password: {
                required: requiredCustomMessage,
                mediumOrStrong: helpers.withMessage('Weak password. Must contain at least 6 characters and at least 1 number.', CustomValidationUtils.mediumOrStrong)
            },
            email: {
                required: requiredCustomMessage,
                email: helpers.withMessage('Email address is not valid.', email),
            },
        }
    }
}
</script>

<style>
.p-component.p-password-panel {
    font-family: 'Trebuchet MS', 'Verdana';
}
.p-divider {
    padding-top: 15px;
    padding-bottom: 15px;
}
.p-divider-solid.p-divider-horizontal:before {
    border-top: 3px solid #f2f4fa;
}

.p-password-info {
    font-weight: bold;
    color: #32364e;
}
.p-password-meter {
    background-color: #f2f4fa;
}
.p-password-strength.weak {
    background-color: #ea5c5c;
}
.p-password-strength.medium {
    background-color: #fff2cc;
}
.p-password-strength.strong {
    background-color: #33cc99;
}
</style>
<style scoped>
*:not(.pi),::v-deep(*:not(.pi)) {
    font-family: "Trebuchet MS", "Verdana";
}

.sign-up-header {
    display: flex;
    align-items: center;
    padding: 10px 35px 30px;
}
.p-button-text {
    font-weight: bold;
    color: #32364e;
}
.p-button-text:enabled:hover {
    background: none;
    color: #33CC99;
}
.p-button-text:enabled:focus {
    box-shadow: none;
    border: none;
}
.forgot-password-button {
    margin-right: auto;
    font-size: 22px;
    padding: 9px 0px;
    border: none;
}
.sign-up-button {
    margin-left: auto;
    font-size: 25px;
    padding: 9px 15px;
}

.sign-in-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 200px auto auto auto;
}

.sign-in-title {
    font-size: 60px;
    color: #32364e;
    padding-bottom: 0.7em;
}

.input-container {
    display: flex;
    padding-bottom: 35px;
}
.input-section {
    width: 100%;
}

.p-password {
    width: 100%;
}

::v-deep(.p-inputtext),
.p-inputtext {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}
::v-deep(.p-inputtext:enabled:focus),
.p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.p-float-label {
    width: 100%;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
}

.input-error .p-inputtext:hover,
::v-deep(.input-error .p-inputtext:hover),
.input-error .p-inputtext:enabled:focus,
::v-deep(.input-error .p-inputtext:enabled:focus),
.input-error .p-inputtext,
::v-deep(.input-error .p-inputtext) {
    border-color: #e63e3e;
}
.input-error .input-label {
    color: #e63e3e;
}
.error-message:nth-child(2) {
    padding-top: 10px;
}
.error-message {
    color: #e63e3e;
    padding-left: 10px;
}

/* Shaking animation */
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(0.375rem);
    }
    30% {
        transform: translateX(-0.375rem);
    }
    45% {
        transform: translateX(0.375rem);
    }
    60% {
        transform: translateX(-0.375rem);
    }
    75% {
        transform: translateX(0.375rem);
    }
    90% {
        transform: translateX(-0.375rem);
    }
    100% {
        transform: translateX(0);
    }
}

.button-container {
    display: flex;
}

.sign-in-button {
    margin-left: auto;
    width: 150px;
    border-radius: 8px;
    background-color: #33CC99;
    font-size: 25px;
    padding: 9px 15px;
    color: white;
    border: 1px solid #33CC99;
    justify-content: center;
}
.sign-in:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
@media (min-width: 761px) {
    .sign-up-header img {max-width: 250px}
}
@media (max-width: 760px) {
  .input-container { flex-direction: column;}
  .forgot-password-button { font-size: 1rem;}
  .sign-in-title { font-size: 3rem;}
  .sign-in-container {width: 80%;}
  .sign-up-header img {max-width: 150px}
  .sign-in-button {
      border-radius: 30px;
      padding: 5px 20px;
      font-size: 20px;
  }
}
</style>
